import React, { Component } from 'react';
import Navbar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer/Footer';
import CollapseCard from '../Components/CollapseCard/CollapseCard';

import HomeImg from '../Images/Photos/home.jpg';
import ContactImg from '../Images/PNGs/contact-img.png';
import ContactImgLG from '../Images/PNGs/contact-img-lg.png';
import DigMkt from '../Images/Photos/DigMkt.jpg';
import Real from '../Images/Photos/RealS.jpg';
import Retail from '../Images/Photos/Retail.jpg';

class Home extends Component {

  render() {
    return (
      <div className="Home">
        <Navbar></Navbar>
        <div className="container-fluid bg-home" id="top">
          <div className="container">
            <div className="row ">
              <div className="col-12 col-md-6">
                <p className="front-title">Hey,<br/>We’re K5.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 d-flex flex-column alig-items-center">
                <p className="big-text -white"><b>Who we are.</b> K5 is an 
                innovative consulting firm focused on Artificial Intelligence 
                solutions, Real Estate, Retail, and Digital Marketing strategies.</p>
                <p className="mb-m -white f-18">In the new digital era, we believe all companies 
                should have the right digital transformation strategy that includes the 
                latest technologies into their business plan and which aligns with business 
                objectives. Technologies like artificial intelligence, machine learning, 
                deep learning, and the cloud have many benefits, helping enterprises build 
                a competitive advantage.</p>
              </div>
              <div className="col-12 col-md-4 col-lg-3 d-flex align-items-end justify-content-center">
                <div className="mask mb-m">
                  <img className="w-100" src={HomeImg} alt="" />
                </div>
              </div>
            </div>
            <div id="work"></div>
          </div>
        </div>

        <div className="container mt-xl">
          <div className="row">
            <div className="col-12">
              <p className="title -blue">What do we do?</p>
              <hr />
              <CollapseCard
              title="Digital Marketing"
              cardname="Manage better, be great ."
              content="We use tools based on artificial intelligence that permits B2B and B2C companies to compile, analyze and classify information automatically, becoming important assets when making business decisions. This type of tool helps managers by suggesting solutions based on past events and outcomes that the technology has recorded over time."
              image={DigMkt}
              />
              <CollapseCard
              title="Real Estate"
              cardname="Manage better, be great ."
              content="We are a real estate company focused on our clients’ needs and budget, allowing us to offer personalized service and advise them on the selling or purchasing process of a property. We look to build the mediums to make this experience as easy as possible, due to this we also offer financial counseling and legal advice. "
              image={Real}
              />
              <CollapseCard
              title="Retailing"
              cardname="Manage better, be great ."
              content="We are a company specialized in the commercialization products for two different types of industries:
              Building industry: by providing them on time with quality products and with the latest technologies needed to streamline constructions.
              Food and services industry: we provide clients with high-quality products for industrial kitchens."
              image={Retail}
              />
            </div>
          </div>
          <div id="services"></div>
        </div>

        <div className="container-fluid bg-bl mt-l py-l">
          <div className="container">
            <p className="title -white">Services .</p>
            <div className="services">
              <a href="" className="service">Management Consulting,</a>
              <a href="" className="service">Social Media Marketing,</a>
              <a href="" className="service"> Community Manager,</a>
              <a href="" className="service">Web Page Design,</a>
              <a href="" className="service">Branding and Logo,</a>
              <a href="" className="service">Advertising,</a>
              <a href="" className="service">Market research,</a>
              <a href="" className="service">Advice on Credit Procedures,</a>
              <a href="" className="service">Personalized Advice on Selling/Purchasing properties,</a>
              <a href="" className="service">Counseling on Purchase of Construction Products,</a>
              <a href="" className="service">Counseling on Purchase of Industrial Kitchen Products.</a>
            </div>
          </div>
        </div>

        <div id="contact"></div>

        <div className="container mt-xl mb-m">
          <div className="row d-flex justify-content-between">
            <div className="col-12">
              <img className="w-100 contact-img" src={ContactImg} alt="" />
            </div>
            <div className="col-md-4">
              <img src={ContactImgLG} alt="" className="contact-img-lg w-100" />
            </div>
            <div className="col-12 col-md-7">
              <p className="contact-title">Hey, <br/> Contact us.</p>
              <p className="contact-text mb-m">Let's get in touch to understand your business objectives and start building a memorable business strategy</p>
              <button className="secondary">Contact Us</button>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Home;