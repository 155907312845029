import React, { Component } from 'react';
import Logo from '../../Images/Logos/logo-blk.svg';
class Navbar extends Component {

  constructor(props) {
    super(props);
    this.handleScroll= this.handleScroll.bind(this);
    this.state = {
      navbarStyle: ''
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    let navbarHeight = event.srcElement.getElementById("k5-nav-scroll").offsetTop;
    let scrollTop = event.srcElement.getElementById("k5-nav-scroll").scrollTop;
    if (scrollTop < navbarHeight) {
      this.setState ({navbarStyle:'-scrolled'});
    }
    else {
      this.setState ({navbarStyle:''});
    }
    console.log(navbarHeight);
    console.log(scrollTop);
  }

  render() {
    return (
      <div id="k5-nav-scroll" className = {`k5-navbar ${this.state.navbarStyle}`}>
        <a href="#top" className="logo-wrapper">
          <img src={Logo} alt="" />
        </a>
        <div className="nav-options">
          <a href="#work" className="nav-link">Work</a>
          <a href="#services" className="nav-link">Services</a>
          <a href="#contact" className="nav-link">Contact</a>
        </div>
      </div>
    );
  }
}

export default Navbar;