import React, { Component } from 'react';

import Logo from '../../Images/Logos/logo-blk.svg';
import Facebook from '../../Images/SVG/facebook.svg';
import Twitter from '../../Images/SVG/twitter.svg';
import Linkedin from '../../Images/SVG/linkedin.svg';
import Youtube from '../../Images/SVG/youtube.svg';

class Footer extends Component {
  render() {
    return (
      <div className="k5-footer">
        <div className="logo-wrapper">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-info">
          <div className="row">
            <div className="col-12 col-md-3 d-flex flex-column">
              <a href="">Copyright Policy</a>
              <a href="">Privacy Policy</a>
            </div>
            <div className="col-12 col-md-3 d-flex">
              <button className="icon"><img src={Facebook} alt="" /></button>
              <button className="icon"><img src={Twitter} alt="" /></button>
              <button className="icon"><img src={Linkedin} alt="" /></button>
              <button className="icon"><img src={Youtube} alt="" /></button>
            </div>
          </div>
        </div>
        <p className="rights">All rights reserved. ®</p>
      </div>
    );
  }
}

export default Footer;