import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

//IMPORT PAGES//
import Home from './Pages/Home';

class App extends React.Component {
  render() {

    return (

    <Router>
      <Switch>
        <Route exact path ="/" render ={() =>(
          <Home/>
        )}/>
      </Switch>
    </Router>

    );
  }
};

export default App;

