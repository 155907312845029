import React, { Component } from 'react';

class CollapseCard extends Component {
  constructor(props){
    super(props);

    this.state = {
      isCardOpen:false
    };
  }

  toggleCard = () => {
    this.setState(prevState => ({ isCardOpen: !prevState.isCardOpen }));
  };

  render() {
    const { isCardOpen } = this.state;

    return (
      <div className={`collapse-card ${isCardOpen ? "-open" : ""}`}>
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="section">
              <p className="subtitle -red mb-0">{this.props.title}</p>
              <button className="text" onClick={() => this.toggleCard()}>View more</button>
            </div>
          </div>
          <div className="col-10">
            <div className="content-card">
              <div className="row mb-s">
                <div className="col-12 col-md-5 pr-md-0">
                  <div className="image-wrap">
                    <img src={this.props.image} alt="" />
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="contnt">
                    <p className="big-text -white">{this.props.cardname}</p>
                    <p className="-white">{this.props.content}</p>
                    <button className="primary">See more</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8">
            <hr />
          </div>
        </div>
      </div>
    );
  }
}

export default CollapseCard;